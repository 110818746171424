$background-color: rgb(252, 252, 252);
$font-color: rgb(17, 17, 17);
//$body-font:  'Source Serif Pro', serif;
$body-font: 'Source Sans Pro', sans-serif;
$header-font: 'Source Sans Pro', sans-serif;

$link-border-color: rgba(0, 0, 0, 0.6);
$link-border-hover-color: rgba(0, 115, 142, 1);

@mixin header {
  font-family: $header-font;
  font-weight: 700;
}

@mixin body {
  font-family: $body-font;
  font-weight: 300;
}