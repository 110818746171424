html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

h1 {
  margin: .67em 0;
  font-size: 2.5em;
}

h2 {
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

video {
  width: 100%;
  height: auto;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

body, button, input, select, optgroup, textarea {
  color: #111;
  font-family: Source Sans Pro, sans-serif;
  font-size: clamp(16px, 4vw, 20px);
  font-weight: 300;
  line-height: 1.7;
}

h1, h2, h3, h4, h5, h6, dl > dt {
  clear: both;
  font-family: Source Sans Pro, sans-serif;
  font-weight: 700;
}

p {
  margin-bottom: 1.5em;
}

dfn, cite, em, i {
  font-style: italic;
}

blockquote {
  margin: 0 1.5em;
}

address {
  margin: 0 0 1.5em;
}

pre {
  background: #eee;
  max-width: 100%;
  margin-bottom: 1.6em;
  padding: 1.6em;
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: .9375rem;
  line-height: 1.6;
  overflow: auto;
}

code, kbd, tt, var {
  font-family: Monaco, Consolas, Andale Mono, DejaVu Sans Mono, monospace;
  font-size: .9375rem;
}

abbr, acronym {
  cursor: help;
  border-bottom: 1px dotted #666;
}

mark, ins {
  background: #fff9c0;
  text-decoration: none;
}

big {
  font-size: 125%;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  background-color: #fcfcfc;
}

hr {
  background-color: #ccc;
  border: 0;
  height: 1px;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li > ul, li > ol {
  margin-bottom: 0;
}

dt {
  font-weight: bold;
}

dd {
  margin: 0 1.5em 1.5em;
}

img {
  max-width: 100%;
  height: auto;
}

figure {
  margin: 1em 0;
}

table {
  width: 100%;
  margin: 0 0 1.5em;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  color: #000c;
  background: #e6e6e6;
  border: 1px solid #ccc;
  border-bottom-color: #bbb;
  border-radius: 3px;
  padding: .6em 1em .4em;
  font-size: .75rem;
  line-height: 1;
}

button:hover, input[type="button"]:hover, input[type="reset"]:hover, input[type="submit"]:hover {
  border-color: #ccc #bbb #aaa;
}

button:active, button:focus, input[type="button"]:active, input[type="button"]:focus, input[type="reset"]:active, input[type="reset"]:focus, input[type="submit"]:active, input[type="submit"]:focus {
  border-color: #aaa #bbb #bbb;
}

input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="number"], input[type="tel"], input[type="range"], input[type="date"], input[type="month"], input[type="week"], input[type="time"], input[type="datetime"], input[type="datetime-local"], input[type="color"], textarea {
  color: #666;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 3px;
}

input[type="text"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="number"]:focus, input[type="tel"]:focus, input[type="range"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="week"]:focus, input[type="time"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="color"]:focus, textarea:focus {
  color: #111;
}

select {
  border: 1px solid #ccc;
}

textarea {
  width: 100%;
}

a {
  color: #111;
  border-bottom: .07em solid #0009;
  text-decoration: none;
  transition: border .5s cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
}

a:visited {
  color: #0009;
}

a:hover, a:focus, a:active {
  border-bottom-color: #00738e;
}

a:focus {
  outline: thin dotted;
}

a:hover, a:active {
  outline: 0;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  word-wrap: normal !important;
  position: absolute !important;
}

.screen-reader-text:focus {
  clip-path: none;
  color: #21759b;
  z-index: 100000;
  background-color: #f1f1f1;
  border-radius: 3px;
  width: auto;
  height: auto;
  padding: 15px 23px 14px;
  font-weight: bold;
  line-height: normal;
  text-decoration: none;
  display: block;
  top: 5px;
  left: 5px;
  box-shadow: 0 0 2px 2px #0009;
  clip: auto !important;
}

#content[tabindex="-1"]:focus {
  outline: 0;
}

.alignleft {
  float: left;
  margin-right: 1.5em;
  display: inline;
}

.alignright {
  float: right;
  margin-left: 1.5em;
  display: inline;
}

.aligncenter {
  clear: both;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.clear:before, .clear:after, .entry-content:before, .entry-content:after, .comment-content:before, .comment-content:after, .site-header:before, .site-header:after, .site-content:before, .site-content:after, .site-footer:before, .site-footer:after {
  content: "";
  table-layout: fixed;
  display: table;
}

.clear:after, .entry-content:after, .comment-content:after, .site-header:after, .site-content:after, .site-footer:after {
  clear: both;
}

header#masthead {
  width: 100%;
  margin: 0;
  padding: 1em 2.5em .5em;
  position: fixed;
  top: 0;
}

.contained {
  margin-left: 2.5em;
  margin-right: 2.5em;
}

.contained > * {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0;
}

section {
  margin-top: 20vh;
}

@media only screen and (width <= 600px) {
  section {
    margin-top: clamp(15vh, 4vw, 30vh);
  }
}

.sixty-forty {
  width: 100%;
  display: flex;
}

.sixty-forty > :first-child {
  width: 40%;
}

.sixty-forty > :nth-child(2) {
  width: 60%;
}

h1, h2 {
  font-weight: 300;
}

h2.section-title {
  text-transform: uppercase;
  margin-bottom: 1em;
  font-weight: 700;
}

.logo {
  height: 27px;
}

.no-border {
  border: none;
}

@media only screen and (width <= 1220px) {
  header#masthead {
    background-color: #fcfcfc;
  }
}

section#about p {
  margin-top: 1em;
}

section#about a:hover.enrise {
  border-bottom-color: #f2a900;
}

section#about a:hover.jimdo {
  border-bottom-color: #006678;
}

section#about a:hover.hellofresh {
  border-bottom-color: #96dc14;
}

section#about a:hover.scribit {
  border-bottom-color: #000050;
}

section#work ol {
  flex-flow: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 1400px;
  list-style: none;
  display: flex;
}

section#work ol > li {
  box-sizing: border-box;
  width: calc(50% - 1.25em);
  margin-top: 2.5em;
  margin-right: 2.5em;
}

section#work ol > li:nth-child(2n) {
  margin-right: 0;
}

@media only screen and (width >= 960px) {
  section#work ol > li:nth-child(2) {
    margin-top: 7em;
  }

  section#work ol > li:nth-child(3) {
    margin-top: 0;
  }
}

@media only screen and (width <= 960px) {
  section#work ol > li {
    flex: 0 100%;
    margin-right: 0;
  }
}

section#work ol :after {
  content: "";
  flex: auto;
}

section#work ol h2 {
  margin: 0;
}

section#work ol p {
  margin-bottom: 0;
}

section#work ol a.learn-more {
  float: right;
}

section#contact ul, section#contact dd {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

section#contact li {
  padding-bottom: 1em;
}

/*# sourceMappingURL=error.9e2b0cce.css.map */
