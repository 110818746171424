// Loyout: 1000px containers that you can break because minimalism should not be boring
// Use .contained to constrain yourself nicely on small and wide viewports.
header#masthead {
  position: fixed;
  padding: 1em 2.5em 0.5em;
  margin: 0;
  width: 100%;
  top: 0;
}

.contained {
  margin-left: 2.5em;
  margin-right: 2.5em;

  > * {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0;
  }
}

section {
  margin-top: 20vh;
  // Smaller screens like the Iphone 6 will be compensated with less whitespace to fit the h1
  @media only screen and (max-width: 600px) {
    margin-top: clamp(15vh, 4vw, 30vh);
  }
}

.sixty-forty {
  display: flex;
  width: 100%;

  > *:nth-child(1) {
    width: 40%;
  }

  > *:nth-child(2) {
    width: 60%;
  }
}

// markup
h1, h2 {
  font-weight: 300;
}

h2.section-title {
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1em;
}

.logo {
  height: 27px;
}

.no-border {
  border: none;
}

header#masthead {
  // When the screen is too small the top logo will overlap with the content
  @media only screen and (max-width: 1220px) {
    background-color: $background-color;
    //box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 30px 0px; unsure if this is not just anoying?
  }
}

section#about {
  p {
    margin-top: 1em;
  }
  a:hover {
    &.enrise {
      border-bottom-color: rgb(242, 169, 0)
    }

    &.jimdo {
      border-bottom-color: rgb(0, 102, 120)
    }

    &.hellofresh {
      border-bottom-color: rgb(150, 220, 20)
    }
    &.scribit {
      border-bottom-color: rgb(0, 0, 80)
    }
  }
}


section#work ol {
  width: 100%;
  max-width: 1400px;
  list-style: none;


  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  > li {
    box-sizing: border-box;
    width: calc(1 / 2 * 100% - (1 - 1 / 2) * 2.5em);
    margin-right: 2.5em;
    margin-top: 2.5em;

    &:nth-child(even) {
      margin-right: 0;
    }

    &:nth-child(2) {
      @media only screen and (min-width: 960px) {
        margin-top: 7em;
      }
    }

    &:nth-child(3) {
      @media only screen and (min-width: 960px) {
        margin-top: 0;
      }
    }

    // We could break at 600px but I rather not fix or rename the envoy control plane project
    @media only screen and (max-width: 960px) {
      flex: 0 100%;
      margin-right: 0;
    }

  }

  :after {
    content: '';
    flex: auto;
  }

  h2 {
    margin: 0;
  }

  p {
    margin-bottom: 0;
  }

  a.learn-more {
    float: right;
  }
}

section#contact {
  ul, dd {
    margin-left: 0;
    padding-left: 0;
    list-style: none;
  }

  li {
    padding-bottom: 1em;
  }
}

